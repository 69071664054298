.cancel,
.submit,
.delete {
  display: inline-block;
  line-height: 1.7rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  padding: 1.3rem 4.8rem;
  transition: all 350ms ease-in-out;
  color: #fff;
  margin-bottom: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  /* Adding a subtle glow */
}

.cancel.w_100,
.submit.w_100,
.delete.w_100 {
  width: 100%;
}

.cancel {
  border: 1px solid var(--gray-6);
}

.cancel:hover {
  background-color: var(--gray-6);
  box-shadow: 0 0 12px rgba(128, 128, 128, 0.5);
  /* Slight glow on hover */
}

.submit {
  border-radius: 5px;
  /* background: #b68b2d; */
  background: #FEC240;

  color: #000000;
  margin-top: 1.5rem;
}

.submit:hover {
  background: #FEC240;
  box-shadow: 0 0 12px rgba(254, 194, 64, 0.7);
  /* Slight glow on hover */
}

.delete {
  background-color: var(--dark-red);
  color: var(--white);
}

.delete:hover {
  background-color: var(--bright-red);
  box-shadow: 0 0 12px rgba(255, 0, 0, 0.6);
  /* Slight glow on hover */
}

.btn_text.disabled {
  background-color: var(--gray-4);
  cursor: auto;
  box-shadow: none;
  /* No glow for disabled state */
}