.action_buttons {
    display        : flex;
    justify-content: flex-end;
}

.action_buttons button {
    display        : flex;
    justify-content: center;
    align-items    : center;
    width          : 4.4rem;
    height         : 4.4rem;
    color          : #AEAEAE;
    border-radius  : 50%;
    transition     : background-color .3s ease-in-out, color .3s ease-in-out;
}

.action_buttons button:not(:last-child) {
    margin-right: 1.5rem;
}

.action_buttons button.trash {
    color: #FF0000;
}

.action_buttons button:hover {
    background-color: var(--primary-color);
}

.action_buttons button:not(.trash):hover {
    color: var(--black);
}
