.legend {
    display: flex;
    flex-direction: column;
    gap: .7rem;
}

.legend-item {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.legend-box {
    width: 10px;
    height: 10px;
    border-radius: 2px;
}

.investment {
    background-color: rgb(223, 192, 188);
}

.maturity {
    background: linear-gradient(145deg,  #fff, rgb(223, 192, 188), #fff, rgb(223, 192, 188), #fff, rgb(223, 192, 188), #fff, rgb(223, 192, 188), #fff, rgb(223, 192, 188), #fff, rgb(223, 192, 188));
}

.legend-text {
    font-size: 12px;
    color: #000000;
}