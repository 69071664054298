.sidebar_top {
    width: 100%;
    height: 100%;
}


.sidebar_top img {
    width: 100%;
}

.sidebar .btn_close {
    position: absolute;
    top: 4rem;
    right: 2.2rem;
   
}

@media (orientation: portrait) {
    .sidebar_top {
        justify-content: space-between;
        align-content: center;
    }
}