@tailwind base;
@tailwind components;
@tailwind utilities;

/* Normal weight */
@font-face {
  font-family: 'Futura';
  src: url('./assets/futura/futur.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Bold weight */
@font-face {
  font-family: 'Futura';
  src: url('./assets/futura/Futura Bold font.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* Bold Italic */
@font-face {
  font-family: 'Futura';
  src: url('./assets/futura/Futura Bold Italic font.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

/* Book */
@font-face {
  font-family: 'Futura';
  src: url('./assets/futura/Futura Book font.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Book Italic */
@font-face {
  font-family: 'Futura';
  src: url('./assets/futura/Futura Book Italic font.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

/* Extra Black */
@font-face {
  font-family: 'Futura';
  src: url('./assets/futura/Futura Extra Black font.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

/* Heavy */
@font-face {
  font-family: 'Futura';
  src: url('./assets/futura/Futura Heavy font.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

/* Heavy Italic */
@font-face {
  font-family: 'Futura';
  src: url('./assets/futura/Futura Heavy Italic font.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

/* Light */
@font-face {
  font-family: 'Futura';
  src: url('./assets/futura/Futura Light font.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* Light Italic */
@font-face {
  font-family: 'Futura';
  src: url('./assets/futura/Futura Light Italic font.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

/* Medium */
@font-face {
  font-family: 'Futura';
  src: url('./assets/futura/futura medium bt.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Medium Italic */
@font-face {
  font-family: 'Futura';
  src: url('./assets/futura/Futura Medium Italic font.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

/* Condensed Light */
@font-face {
  font-family: 'Futura';
  src: url('./assets/futura/Futura-CondensedLight.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

/* Black */
@font-face {
  font-family: 'Futura';
  src: url('./assets/futura/Futura XBlk BT.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

/* Apply fonts globally */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Futura', sans-serif !important;
}

/* Global color variables */
:root {
  --black: #000;
  --white: #FFF;
  --gray-1: #E6E6E6;
  --gray-2: #CCCCCC;
  --gray-3: #B3B3B3;
  --gray-4: #999999;
  --gray-5: #808080;
  --gray-6: #666666;
  --gray-7: #4D4D4D;
  --gray-8: #333333;
  --gray-9: #1A1A1A;
  --bright-red: #EB5757;
  --dark-red: #660101;
}

/* Reset and base styles */
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  position: relative;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol,
span,
a {
  font-family: 'Futura', sans-serif !important;
}

svg,
img {
  vertical-align: middle;
}

button,
input,
textarea,
select {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input,
optgroup,
select,
textarea {
  border: 0;
}

input:focus-visible,
textarea:focus-visible,
select:focus-visible,
button:focus-visible {
  outline: none;
}

.w_100 {
  width: 100%;
}

/* Media queries for responsive design */
@media (max-width: 991.98px) {
  button {
    cursor: auto;
  }
}