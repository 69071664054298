.screen_wrapper{
    display: grid;
    grid-auto-rows: 10% 90%;
    width: 100%;
    height: 100%;
}
.screen_header {
    background-color: var(--black);
    border-radius: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 4rem; 
}

.screen_heading {
    font-weight: 600;
    font-size  : 2rem;
    line-height: 3rem;
    color: var(--white);
}


.data_table_wrapper {
    padding-top: 2.5rem;
}

.data_table_inner {
    width           : 100%;
    height          : 100%;
    background-color: var(--black);
    border-radius   : 1rem;
    padding         : 3.3rem 4.4rem 4.4rem;
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
}

/* data table */
.data_table {
    width             : 100%;
    height: 100%;
    border-collapse   : separate;
    /* border-spacing : 0 1.5rem; */
    table-layout      : fixed;
    display: grid;
    grid-template-rows: 10% 80% 10%;
}

.data_table tbody {
    display           : block;
    overflow-y        : scroll;
    -ms-overflow-style: none;
    scrollbar-width   : none;
}

.data_table tbody::-webkit-scrollbar {
    display: none;
}

.data_table thead,
.data_table tbody tr {
    display        : table;
    width          : 100%;
    border-collapse: separate;
    table-layout   : fixed;
}

.data_table tbody tr {
    border-spacing: 0 .8rem;
}

.data_table tbody tr td {
    background: rgba(26, 26, 26, 0.9);
}

.data_table th,
.data_table td {
    padding-left : 3.6rem;
    padding-right: 3.6rem;
}

.data_table th:not(:last-child),
.data_table td:not(:last-child) {
    text-align: left;
}

.data_table th:last-child,
.data_table td:last-child {
    text-align: right;
}

.data_table th {
    font-weight   : 600;
    font-size     : 1rem;
    line-height   : 2.8rem;
    color         : var(--primary-color);
    padding-bottom: 2rem;
}

.data_table tbody td:first-child {
    border-top-left-radius   : 1rem;
    border-bottom-left-radius: 1rem;
}

.data_table tbody td:last-child {
    border-top-right-radius   : 1rem;
    border-bottom-right-radius: 1rem;
}

/* .data_table tbody>tr:not(:last-child)>td {
    border-bottom: 1.5rem solid var(--black); 
} */

.data_table td {
    font-weight   : 500;
    font-size     : 1.5rem;
    color         : var(--white);
    padding-top   : 1rem;
    padding-bottom: 1rem;
    margin-bottom : 1.5rem;
}

.data_table td>img {
    max-width: 6rem;
    border-radius: 50%;
}

.action_buttons_wrapper {
    position: relative;
}

.three_dots {
    display: none;
}

.action_buttons {
    display        : flex;
    justify-content: center;
}

.action_buttons button {
    display        : flex;
    justify-content: center;
    align-items    : center;
    width          : 4.4rem;
    height         : 4.4rem;
    color          : #AEAEAE;
    border-radius  : 50%;
    transition     : background-color .3s ease-in-out, color .3s ease-in-out;
}

.action_buttons button:not(:last-child) {
    margin-right: 1.5rem;
}

.action_buttons button.trash {
    color: #FF0000;
}

.action_buttons button:hover {
    background-color: var(--primary-color);
}

.action_buttons button:not(.trash):hover {
    color: var(--black);
}



@media (orientation: portrait) {
    .screen_header {
        padding: 2.2rem 1.5rem;
    }
    .screen_heading {
        font-size  : 1.2rem;
        line-height: 1.5rem;
    }

    .data_table_inner {
        padding: .5rem 1.5rem 2.5rem;
    }

    .data_table {
        border-spacing: 0 1rem;
    }

    .data_table th,
    .data_table td {
        padding-left : 1.2rem;
        padding-right: 1.2rem;
    }

    .data_table th {
        font-size     : 1rem;
        line-height   : 1.3rem;
        padding-bottom: .5rem;
    }

    .data_table td {
        font-size     : 1.2rem;
        line-height   : 1.5rem;
        padding-top   : 2rem;
        padding-bottom: 2rem;
    }

    .data_table td>img {
        max-width: 4rem;
    }

    .action_buttons {
        position        : absolute;
        top             : calc(100% + 1rem);
        right           : 0;
        background-color: var(--black);
        border-radius   : .8rem;
        padding         : 1rem 1.4rem;
    }

    .action_buttons::after {
        content      : '';
        position     : absolute;
        top          : -.7rem;
        right        : .5rem;
        width        : 0;
        height       : 0;
        border-left  : 1rem solid transparent;
        border-right : 1rem solid transparent;
        border-bottom: 12px solid var(--black);
    }

    .three_dots {
        display: inline-block;
    }

    .action_buttons button {
        width           : 3rem;
        height          : 3rem;
        background-color: rgba(26, 26, 26, .9);
    }

    .action_buttons button>svg {
        width : 1.6rem;
        height: 1.6rem;
    }
}