.file_upload_input[type="file"] {
    display: none;
}

.preview_image {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
    width: 100%;
    padding-bottom: 2.5rem;
    cursor: pointer;
    opacity: 0.7;
}

.preview_image:hover {
    opacity: 1;
}

.preview_image img {
    /* width: 100%; */
    margin: auto;
    height: 15rem;
    object-fit: fill;
}

.preview_image img {
    max-width: 100%;
    object-fit: cover;
}