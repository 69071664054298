.form {
  width: 100%;
}
.input_field_label {
  display: block;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: var(--gray-3);
  text-transform: capitalize;
  margin-bottom: 1rem;
}

.input_field {
  border-radius: 5px;
  background-color: #1a1a1a;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: normal;
  /* color: var(--gray-4); */
  color: #fff;
  padding: 1.4rem 2rem;
  border: 1px solid var(--gray-6);
  margin-bottom: 1.5rem;
  width: 100%;
}
.input_field::placeholder{
  font-size: 1.4rem;
}
.input_field.big_height {
  min-height: 16rem;
}

.input_field:disabled {
  color: var(--gray-4);
}

.input_field::placeholder {
  color: var(--gray-4);
}

.input_field:not(:disabled):hover {
  border-color: var(--gray-3);
}

.input_field:not(:disabled):focus-visible {
  border-color: var(--gray-5);
}

select {
  appearance: none;
  /* background-image: url(../../../../Assets/Images/select-down-arrow.svg); */
  background-size: 2rem 2rem;
  background-position: right 1.2rem center;
  background-repeat: no-repeat;
}

input[type="date"],
input[type="time"] {
  appearance: none;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* .logo_wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
} */
