.chart-section::-webkit-scrollbar {
    height: 8px;
}

.chart-section::-webkit-scrollbar-track {
    background: rgba(158, 153, 153, 0.317);
}

.chart-section::-webkit-scrollbar-thumb {
    background: rgb(45, 45, 45);
    border-radius: 4px;
}

.chart-section::-webkit-scrollbar-thumb:hover {
    background: rgb(75, 75, 75);
}

.chart-section .graphWrapper {
    scrollbar-width: thin;
    scrollbar-color: rgb(45, 45, 45) rgba(158, 153, 153, 0.317);
}


.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4dvh 10dvh;
    padding-bottom: 15dvh;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
}

.separator {
    height: full;
    position: absolute;
    width: 1px;
    left: 50%;
    bottom: 0;
    top: 0;
    background-color: rgb(0, 0, 0);
}

.chart-section {
    width: 100%;
    display: flex;
    gap:10px;
    align-items: flex-end;
    justify-content: center;
    overflow-x: auto;
}

.investment-bar {
    position: relative;
    margin: 0px 20px;
}

.investment-bar .bar {
    width: 10px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.investment-bar .bar .bar-info {
    font-size: 14px;
    color: #0b0b0b;

}

.investment-bar .tooltip {
    position: absolute;
    left: 85px;
    bottom:75%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(45, 45, 45, 0.514);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 5px;
    min-width: 100px;
    width: max-content;
    text-align: center;
    z-index: 3000;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
}

.tooltip-text>div {
    display: flex;
    justify-content: space-between;
}

.investment-bar:hover .tooltip,
.investment-bar.hover .tooltip {
    opacity: 1;
    pointer-events: auto;
}

.investment-bar .tooltip .tooltip-line {
    width: 2px;
    height: 40px;
    background-color: rgb(45, 45, 45);
    margin: 0 auto 10px;
    display: none;
}

.investment-bar .tooltip .tooltip-text {
    border-radius: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: #333;
}

.investment-bar .tooltip .tooltip-text:first-child {
    font-weight: bold;
    margin-bottom: 5px;
}

.chart-container {
    /* border-left: 1px solid rgba(45, 45, 45, 0.15);
    border-right: 1px solid rgb(45, 45, 45, 0.15);
    border-bottom: 1px solid rgb(45, 45, 45, 0.15); */
    border: 1px solid rgb(21, 21, 21);
    border-top: none;
    position: relative;
    margin-top: 50px;
}


.chart-scale {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
}

.scale-mark {
    height: calc(100% / 5);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.scale-mark span {
    font-size: 14px;
    color: #000000;
    position: absolute;
    left: -60px;
    top: -10px;

}

.scale-mark::before {
    content: '';
    width: 15px;
    height: 1px;
    background-color: rgb(0, 0, 0);
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    top: 0;
}

.dateWrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 50%;
    height: 140px !important;
    display: flex;
    gap: 20px;
    justify-content: center;
    overflow: hidden;
    overflow-x: auto;
}

.dateWrapper_matured {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 50%;
    height: 50px;
    display: flex;
    gap: 20px;
    justify-content: center;
}

.dateMilestone {
    width: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0px 15px;
}

.dateMilestone-line {
    position: absolute;
    width: 1px;
    height: 10px;
    background-color: #fff;
}

.dateMilestone-text {
    margin-top: 30px;
    font-size: 12px;
    color: #000000;
    text-align: center;
    width: max-content;
    rotate: 270deg;
    /* font-weight: bold; */
}

.barAnim {
    animation: barAnim 1.5s ease-in;
}

@keyframes barAnim {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}